import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  colors: {
    primary: 'hsl(210deg 50% 40%)',
    primaryHover: 'hsl(210deg 50% 35%)',
    secondary: 'hsl(0deg 60% 50%)',
    secondaryHover: 'hsl(0deg 60% 40%)',
    error: 'hsl(0deg 70% 45%)',
    background: 'hsl(0deg 0% 95%)',
    backgroundCard: 'hsl(0deg 0% 90%)',
    tooltipBackground: 'hsl(210deg 20% 90%)',
    textPrimary: 'hsl(210deg 15% 20%)',
    textSecondary: 'hsl(0deg 0% 40%)',
    textWhite: 'hsl(0deg 0% 100%)',
    actionHover: 'hsla(210deg 50% 40% / 20%)',
    border: 'hsla(210deg 15% 20% / 20%)',
    borderHover: 'hsla(210deg 15% 20% / 40%)',
    shadow: 'hsla(0deg 0% 0% / 15%)',
    buttonBackground: 'hsla(0deg 0% 100% / 80%)',
    closeButton: 'hsla(0deg 0% 50% / 50%)',
  },
  typography: {
    heading1: {
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2rem',
    },
    heading2: {
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '1.5rem',
    },
    bodyLarge: {
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '1.125rem',
    },
    body: {
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '1rem',
    },
    bodySmall: {
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '0.875rem',
    },
    buttonText: {
      fontWeight: '600',
      lineHeight: '14px',
      fontSize: '0.75rem',
    },
  },
  layout: {
    headerHeight: 100,
    mobileHeaderHeight: 64,
    filterBarHeight: 50,
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
    xLarge: '32px',
    xxLarge: '40px',
  },
};

export const GlobalStyles = createGlobalStyle`
html,
body {
  width: 100vw;
  height: 100vh;
  font-family: var(--font-poppins);
  color: hsl(210deg 15% 20%); /* Primary text color: dark muted navy */
  background-color: hsl(0deg 0% 95%); /* Light neutral background */
}
*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
`;
