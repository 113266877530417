'use client';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { GlobalStyles, lightTheme } from '@styles/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Resource } from 'i18next';
import { ThemeProvider } from 'styled-components';

import { TranslationsProvider } from '@components/TranslationsProvider';

import { store } from '../redux/storeProvider';

export interface ClientProvidersProps {
  locale: string;
  children: ReactNode;
  resources: Resource;
  namespaces: string[];
}

export const ClientProviders = ({
  children,
  locale,
  resources,
  namespaces,
}: ClientProvidersProps) => {
  const queryClient = new QueryClient();
  return (
    <TranslationsProvider locale={locale} resources={resources} namespaces={namespaces}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <Provider store={store}>{children}</Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </TranslationsProvider>
  );
};
