// types.ts
export enum ActionTypes {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

export interface IncrementAction {
  type: ActionTypes.INCREMENT;
}

export interface DecrementAction {
  type: ActionTypes.DECREMENT;
}

export type Action = IncrementAction | DecrementAction;
